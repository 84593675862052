import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  List,
  ListItemButton,
  styled,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ScrollBar from "simplebar-react";
import { index, MobileIndex } from "./topMenuList";

// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: 80,
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: { left: -80 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",
  justifyContent: "center",
  "&:hover": { backgroundColor: "transparent" },
}));

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = useState("");

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    const currentPath = location.pathname;
    const activeItem = MobileIndex.find((item) => item.path === currentPath);
    if (activeItem) {
      setActive(activeItem.title);
    }
  }, [location.pathname]);

  const handleActiveMainMenu = (menuItem: any) => () => {
    setActive(menuItem.title);
    navigate(menuItem.path);
    closeMobileSideBar();
  };

  // main menus content
  const mainSideBarContent = (
    <List sx={{ height: "100%" }}>
      <StyledListItemButton disableRipple>
        <img src={"/icon.png"} style={{ width: '50px', height: '50px' }} />
      </StyledListItemButton>

      <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
        {index.map((nav, index) => (
          <Tooltip title={nav.title} placement="right" key={index}>
            <StyledListItemButton
              disableRipple
              onClick={handleActiveMainMenu(nav)}
            >
              <nav.Icon
                sx={{
                  color: active === nav.title ? "primary.main" : "secondary.400",
                }}
              />
            </StyledListItemButton>
          </Tooltip>
        ))}
      </ScrollBar>
    </List>
  );

  // for mobile device
  if (downMd) {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          boxShadow: (theme) => theme.shadows[8],
          backgroundColor: (theme) => theme.palette.background.paper,
          zIndex: 1100,
        }}
      >
        <BottomNavigation
          value={active}
          onChange={(event, newValue) => {
            setActive(newValue);
          }}
          showLabels
        >
          {MobileIndex.map((nav, index) => (
            <BottomNavigationAction
              key={index}
              icon={<nav.Icon />}
              onClick={handleActiveMainMenu(nav)}
              value={nav.title}
              sx={{
                color: active === nav.title ? "primary.main" : "secondary.400",
              }}
            />
          ))}
        </BottomNavigation>
      </Box>
    );
  }

  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

export default DashboardSideBar;
