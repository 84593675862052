import { Box, styled, Typography } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSideBar";
import { useGlobalState } from "../../data-context";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: '100vw',
  margin: "auto",
  top: 0,
  right: 0,
  left: 0,
  [theme.breakpoints.down("md")]: {
    maxWidth: '100vw',
    margin: "auto",
    top: 0,
    right: 0,
    left: 0,
  },
}));




const DashboardLayout: FC = ({ children }) => {
  const [showMobileSideBar, setShowMobileSideBar] = useState(window.innerWidth <= 768);
  const {state,} = useGlobalState();

  const navigate = useNavigate();



  return (
    <Fragment>
      <DashboardSidebar
        showMobileSideBar={showMobileSideBar}
        closeMobileSideBar={() => {null}}
      />

      <Wrapper>
        <DashboardNavbar
          setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
        />
        <Box sx={{width: '100vw'}} display={'flex'} justifyContent={'center'}>
          <Box sx={{width: showMobileSideBar ? '100vw' : '75vw', paddingBottom: '75px', pl: '12px', pr: '12px'}}  
            flexDirection={'column'} display={'flex'} justifyContent={'center'}>
              {children || <Outlet />}
              
          </Box>
        </Box>
       
      </Wrapper>
    </Fragment>
  );
};

export default DashboardLayout;
