import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import React, { FC, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LightTextField from "../../components/LightTextField";
import { H1, Paragraph, Small } from "../../components/Typography";
import { TextFieldWrapper } from "../../components/authentication/StyledComponents";
import { loginUser } from "../../firebase/init";

const Login: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    submit: null,
    remember: true,
  };
  // form field value validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password should be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .required("Password is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        setLoading(true);
        try {
          const login = await loginUser(values.email, values.password);
          if (login.success) {
            toast.success("Logged In Successfully");
            navigate("/dashboard/");
          } else {
            toast.error('Something went wrong, please try again.')
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      },
    });
  return (
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Card sx={{ padding: 4, boxShadow: 1 , 
              display: "flex",
              flexDirection: "column",
              borderRadius: 8,
              justifyContent: "center",
              width: { xs: '100%', md: '80%' }, 
              maxWidth: { xs: '100%', md: '600px' },
            }}>
          <Box
            alignItems="center"
            flexDirection="column"
            sx={{ 
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
            mb={5}
          >
          <Box width={400} mb={1}>
            <img src={'/logo.png'} style={{height: 'auto', width: '100%'}} />
          </Box>
          <H1 fontSize={28} fontWeight={700}>
            SIGN IN
          </H1>
        </Box>

        <Box justifyContent="space-between" my="1rem">
          
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Box justifyContent="space-between">
              <TextFieldWrapper  sx={{ width: "100%" }} >
                <Paragraph fontWeight={600} mb={1}>
                  Email
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="email"
                  type="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </TextFieldWrapper>

              <TextFieldWrapper sx={{ width: "100%" }} >
                <Paragraph fontWeight={600} mb={1}>
                  Password
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>
            </Box>

            <Box mt={2} alignItems="center" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Switch
                    name="remember"
                    checked={values.remember}
                    onChange={handleChange}
                  />
                }
                label="Remember Me"
                sx={{ "& .MuiTypography-root": { fontWeight: 600 } }}
              />
              <Link to="/forget-password">
                <Small color="secondary.red">Forgot Password?</Small>
              </Link>
            </Box>

            {error && (
              <FormHelperText
                error
                sx={{
                  mt: 2,
                  fontSize: 13,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </FormHelperText>
            )}

            <Box sx={{ my: 2 }}>
              {loading ? (
                <LoadingButton loading fullWidth variant="contained">
                  Sign In
                </LoadingButton>
              ) : (
                <Button fullWidth type="submit" variant="contained">
                  Sign In
                </Button>
              )}
            </Box>
          </form>

          {/* <Small margin="auto" color="text.disabled">
            Don't have an account?{" "}
            <Link to="/register">
              <Small color="primary.main">Create an account</Small>
            </Link>
          </Small> */}
        </Box>
      </Card>
    </Box>
  );
};

export default Login;
