import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, createUserWithEmailAndPassword, getAuth, sendEmailVerification, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { get, getDatabase, ref, set } from "firebase/database";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';
import { getFirestore, collection, doc, setDoc, updateDoc, getDoc, getDocs } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { useAuthState } from 'react-firebase-hooks/auth';
import { log } from 'console';


const firebaseConfig = {
  apiKey: "AIzaSyAS2qQ2vQ-x3AZXooX3eOidmyek_L4xHpc",
  authDomain: "hr-hive.firebaseapp.com",
  projectId: "hr-hive",
  storageBucket: "hr-hive.appspot.com",
  messagingSenderId: "202509061433",
  appId: "1:202509061433:web:c06b4e1ef738bfcdb9ece8",
  measurementId: "G-NQ4KK0LH54",
  databaseURL: "https://hr-hive-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase app
export const app = initializeApp(firebaseConfig);

export const fAuth = getAuth(app);

// Initialize other Firebase services
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');
export const functions = getFunctions(app);
export const messaging = getMessaging(app);
export const performance = getPerformance(app);
export const remoteConfig = getRemoteConfig(app);
export const db = getFirestore(app);
export const database = getDatabase(app);


// Request permission from the user to receive notifications
const requestPermission = async () => {
  try {
    await Notification.requestPermission();
    // Get the FCM token (registration token) for the current device
    const token = await getToken(messaging);
    console.log("FCM Token:", token);
    // Handle token (e.g., store it on your server)
  } catch (err) {
    console.log("Permission denied or error occurred", err);
  }
};

// Request permission and handle incoming messages
const initializeMessaging = async () => {
  await requestPermission();

  // Handle incoming messages within the app
  onMessage(messaging, (payload) => {
    console.log("Message received:", payload);
    // Process the message and update your app's UI accordingly
  });
};

initializeMessaging();




/**
 * Registers a new user with the provided email, password, and full name.
 * 
 * @param email - The email address of the user.
 * @param password - The password for the user account.
 * @param fullName - The full name of the user.
 * @param registeringAsAdmin - Optional. Indicates whether the user is registering as an admin.
 * @returns A Promise<{ success: boolean, data?: any, error?: string }> indicating the success or failure of the registration process.
 */
export const registerUser = async (email, password, fullName, businessId, employeeId) => {
  try {
    let [firstName, lastName] = ['', '']
    let capitalizedFirstName = '';
    let capitalizedLastName = '';
    try {
      [firstName, lastName] = fullName.split(' ');
      capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      capitalizedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    } catch (error) {
      toast.error('Please enter your full name');
      
    }
    const { user } = await createUserWithEmailAndPassword(fAuth, email, password);
    const e = await sendEmailConfirmation();
    await setDoc(doc(db, 'users', user.uid), {
      id: user.uid,
      email,
      businessId,
      employeeId,
      isAdmin: false,
      firstName: capitalizedFirstName,
      lastName: capitalizedLastName
    });
    console.log('User registered successfully!', e);
    return { success: true };
  } catch (error) {
    console.error('Error registering user:', error);
    return { success: false, error: error.message };
  }
}


/**
 * Updates the user data in the database.
 * 
 * @param user - The user object containing updated data.
 * @returns A Promise<{ success: boolean, data?: any, error?: string }> indicating the success or failure of the update process.
 */
export const updateUser = async (user) => {
  const [u, loading, e] = useAuthState(fAuth);
  if (!loading) {

    try {
      await updateDoc(doc(db, 'users', u.uid), user);
      console.log('User updated successfully!');
      return { success: true };
    } catch (error) {
      console.error('Error updating user:', error, e);
      return { success: false, error: error.message };
    }
  }
}


/**
 * Retrieves the user data from the database.
 * 
 * @returns A Promise<{ success: boolean, data?: any, error?: string }> containing the user data.
 */
export const getUserData = async () => {
  console.log(fAuth.currentUser)
  try {
    const docSnap = await getDoc(doc(db, 'users', fAuth.currentUser?.uid));
    if (docSnap.exists()) {
      console.log('User data:', docSnap.data());
      return { success: true, data: docSnap.data() };
    } else {
      console.log('User data does not exist');
      return { success: false, error: 'User data does not exist' };
    }
  } catch (error) {
    console.error('Error getting user data:', error);
    return { success: false, error: error.message };
  }
}





/**
 * Logs in a user with the provided email and password.
 * 
 * @param email - The email address of the user.
 * @param password - The password for the user account.
 * @returns A Promise<{ success: boolean, data?: any, error?: string }> indicating the success or failure of the login process.
 */
export const loginUser = async (email, password) => {
  const s = await setPersistence(fAuth, browserSessionPersistence).then(async () => {
    try {
      await signInWithEmailAndPassword(fAuth, email, password);
      localStorage.setItem('uid', fAuth.currentUser.uid);
      console.log('User logged in successfully!');
      return { success: true };
    } catch (error) {
      console.error('Error logging in user:', error);
      return { success: false, error: error.message };
    }
  })
  .catch((error) => {
    console.error('Error logging in user:', error);
    return { success: false, error: error.message };
  });

  if (s.success) {
    const user = await getUserData();
    return user;
  }
}












/**
 * Sends a password reset email to the provided email address.
 * 
 * @param email - The email address of the user.
 * @returns A Promise<{ success: boolean, error?: string }> indicating the success or failure of the password reset process.
 */
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(fAuth, email);
    toast('Password reset email sent successfully!');
    return { success: true };
  } catch (error) {
    toast.error('Error sending password reset email:', error);
    console.error('Error sending password reset email:', error);
    return { success: false, error: error.message };
  }
}







/**
 * Sends an email confirmation to the user's email address.
 * 
 * @returns A Promise<{ success: boolean, error?: string }> indicating the success or failure of the email confirmation process.
 */
export const sendEmailConfirmation = async () => {
  try {
    const user = fAuth.currentUser;
    if (user) {
      await sendEmailVerification(user);
      console.log('Email confirmation sent successfully!');
      toast('Email confirmation sent successfully!')
      return { success: true };
    } else {
      console.error('No user found');
      return { success: false, error: 'No user found' };
    }
  } catch (error) {
    console.error('Error sending email confirmation:', error);
    return { success: false, error: error.message };
  }
}












/**
 * Uploads data to the specified storage reference path.
 * 
 * @param refPath - The reference path in the database.
 * @param data - The data to upload.
 * @returns A Promise<{ success: boolean, data?: string, error?: string }> containing the unique ID of the uploaded data.
 */
export const uploadDataToStorage = async (refPath, data) => {
  try {
    const uniqueId = generateUniqueId();
    await setDoc(doc(db, refPath, uniqueId), data);
    return { success: true, data: uniqueId };
  } catch (error) {
    console.error('Error uploading data to storage:', error);
    return { success: false, error: error.message };
  }
}












/**
 * Updates data at the specified reference path in the database.
 * 
 * @param refPath - The reference path in the database.
 * @param data - The data to update.
 * @returns A Promise<{ success: boolean, error?: string }> indicating the success or failure of the update process.
 */
export const updateData = async (refPath, data) => {
  try {
    await updateDoc(doc(db, refPath), data);
    return { success: true };
  } catch (error) {
    return { success: false, error: error.message };
  }
}














/**
 * Retrieves data from the specified reference path in the database.
 * 
 * @param refPath - The reference path in the database.
 * @returns A Promise<{ success: boolean, data?: any, error?: string }> containing the retrieved data.
 */
export const getData = async (refPath) => {
  try {
    const querySnapshot = await getDocs(collection(db, refPath));
    const data = querySnapshot.docs.map(doc => doc.data());
    return { success: true, data };
  } catch (error) {
    console.error('Error getting data:', error);
    return { success: false, error: error.message };
  }
}












/**
 * Generates a unique ID using a combination of timestamp and random number.
 * 
 * @returns A string containing the generated unique ID.
 */
export const generateUniqueId = () => {
  const timestamp = Date.now().toString();
  const randomNumber = Math.floor(Math.random() * 100000).toString();
  return `${timestamp}-${randomNumber}`;
}






export const uploadState = async (u, state) => {
    try {
      const dbRef = ref(database, u.uid)
      await set(dbRef, state);
    } catch (error) {
      throw error;
    }
}



export const retrieveState = async (u) => {

    try {
      const dbRef = ref(getDatabase(), u.uid);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data found for user:", u.uid);
        return null;
      }
    } catch (error) {
      toast.error('Something went wrong, you\'ll need to log in again');
      await fAuth.signOut();
      throw error;
    }
  
};