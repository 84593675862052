import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/authentication/AuthGuard";
import React from "react";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

/** LAZY LOADING COMPONENETS */
const Login = Loadable(lazy(() => import("./pages/registration/Login")));
const Register = Loadable(lazy(() => import("./pages/registration/Register")));  // Ensure this path is correct
const ForgetPassword = Loadable(lazy(() => import("./pages/registration/ForgetPassword")));
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));
const Discount = Loadable(lazy(() => import("./pages/dashboards/discount")));
const EmployeeOnboarding = Loadable(lazy(() => import("./pages/onboarding/org/main")));
const Rota = Loadable(lazy(() => import("./pages/dashboards/shift-management/main")));
const Staff = Loadable(lazy(() => import("./pages/dashboards/staff")));
const StarterChecklist = Loadable(lazy(() => import("./components/Layouts/StarterChecklist")));

// error
const Error = Loadable(lazy(() => import("./pages/404")));
const CS = Loadable(lazy(() => import("./pages/coming-soon")));
const Pricing = Loadable(lazy(() => import("./pages/pricing")));

// routes
const routes = [
  {
    path: "/dashboard",
    element: <Navigate to="/" />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "starter",
    element: <StarterChecklist />,
  },
  {
    path: "onboarding/:employeeId/:businessId/:email/:firstName/:lastName",
    element: <Register />,  // This should map to your Register component correctly
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "feed",
        element: <DashboardSaaS />,
      },
      {
        path: "",
        element: <Rota />,
      },
      {
        path: "docs",
        element: <Staff />,
      },
      {
        path: "discounts",
        element: <Discount />,
      },
      {
        path: "settings",
        element: <EmployeeOnboarding />,
      },
      {
        path: "*",
        element: <Error />,
      }
    ],
  },
  {
    path: "*",
    element: <Error />,
  }
];

export default routes;
