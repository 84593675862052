import { createTheme, PaletteColorOptions } from '@mui/material/styles';

const HiveTheme = createTheme({
  palette: {
    primary: {
      main: '#FFD400', // Bright yellow
      light: '#FFE84C', // Lighter yellow
      dark: '#C6AB00', // Darker yellow
      red: '#FF5757',
      purple: '#7ED957',
      yellow: '#0CC0DF',
    } as PaletteColorOptions,
    secondary: {
      main: '#FF5757',
      light: '#FF8F8F',
      dark: '#D63434',
      red: '#FF5757',
      purple: '#7ED957',
      yellow: '#0CC0DF',
    } as PaletteColorOptions,
    error: {
      main: '#FD396D',
    },
    success: {
      main: '#2CC5BD',
    },
    warning: {
      main: '#FFE91F',
      dark: '#FFD600',
    },
    info: {
      main: '#A798FF',
    },
    divider: '#c3d2e9',
    background: {
      default: '#f3f4f9',
    },
    text: {
      primary: '#00000095',
      secondary: '#8c99b3',
      disabled: '#c3d2e9',
    },
    mode: 'light',
  },
});

export default HiveTheme;
