import { Avatar, Badge, Box, ButtonBase, Divider, styled } from "@mui/material";
import { FC, Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout";
import { H6, Small, Tiny } from "../../Typography";
import { signOut } from "firebase/auth";
import { fAuth } from "../../../firebase/auth/init";
import { useGlobalState } from "../../../data-context";

// styled components
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

const ProfilePopover: FC = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const {state, } = useGlobalState();
  
  const handleMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "7%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          <Avatar 
              alt={state.user?.firstName} src={state.user?.avatar } variant="circular"
              sx={{ 
                alignSelf: 'center', width: 30, height: 30, ml: 1 ,
                fontSize: '18px', fontWeight: 'bold', backgroundColor: 'primary.main', 
              }}
            >
              {state.user?.firstName?.charAt(0)}
            </Avatar>

        </Badge>
      </ButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <Box alignItems="center" display={'flex'} flexDirection={'row'}>

            <Avatar 
              alt={state.user?.firstName} src={state.user?.avatar } variant="circular"
              sx={{ 
                alignSelf: 'center', width: 30, height: 30, ml: 1 ,
                fontSize: '18px', fontWeight: 'bold', backgroundColor: 'primary.main', 
              }}
            >
              {state.user?.firstName?.charAt(0)}
            </Avatar>
            
            <Box ml={1}>
              <H6>{state.user?.firstName} {state.user?.lastName}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {state.user?.email}
              </Tiny>
            </Box>
          </Box>
        }
      >
        <Box pt={1}>
          <StyledSmall
            onClick={() => handleMenuItem("/settings")}
          >
            Settings
          </StyledSmall>

          

          <Divider sx={{ my: 1 }} />

          <StyledSmall
            onClick={() => {
              signOut(fAuth).then(() => {
              localStorage.clear();
              toast.success("You Logout Successfully");
              window.document.location.reload();
            });}}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
