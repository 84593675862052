import React, { createContext, useContext, useEffect, useState } from "react";
import { LoadingView } from "./components/Layouts/loading";
import { fAuth, db, retrieveState, uploadState } from "./firebase/init";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [user, loading] = useAuthState(fAuth);
  const [state, setState] = useState({
    business: {},
    user: {},
    isLoading: true
  });

  useEffect(() => {
    setState({ ...state, isLoading: loading });
  }, [loading]);

  const retrieveStateFromFirestore = async () => {
    if (user) {
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setState(prevState => ({ ...prevState, user: userData }));
          try {
            if (userData.businessId) {
              const businessDocRef = doc(db, "organizations", userData.businessId);
              const businessDocSnap = await getDoc(businessDocRef);
              if (businessDocSnap.exists()) {
                setState(prevState => ({
                  ...prevState,
                  business: businessDocSnap.data(),
                  isLoading: false
                }));
                const oneMonthAgo = new Date(); // Get current date
                oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1); // Subtract one month
                
                  const q = query(
                      collection(db, "announcements"),
                      where("businessId", "==", userData.businessId),
                      // where("datePosted", ">=", oneMonthAgo)
                  );
          
                  try {
                      const querySnapshot = await getDocs(q);
                      const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                      // handle the posts as needed, e.g., setPosts(posts);
                      setState(prevState => ({
                        ...prevState,
                        posts: posts,
                        isLoading: false
                      }));
                      console.log(posts)
                  } catch (error) {
                      console.error("Error fetching posts:", error);
                  }
              }
            }
          } catch (error) {
            setState(prevState => ({ ...prevState, isLoading: false, user: userData}));
          
          }
        }
      } catch (error) {
        console.error("Error retrieving state from Firestore:", error);
        toast.error('something went wrong, please sign in again!')
        fAuth.signOut()
        // retrieveStateFromDatabase();
      }
    }
  };
  useEffect(() => {
    retrieveStateFromFirestore();
  }, [user]);

  return (
    <DataContext.Provider value={{ state, setState }}>
      {children}
      {(state.isLoading || !state.user || !state.business) && <LoadingView />}
    </DataContext.Provider>
  );
};


export const useGlobalState = () => useContext(DataContext);