import "nprogress/nprogress.css";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
// import "simplebar/dist/simplebar.min.css";
import App from "./App";
import { DataProvider } from "./data-context";


import "./firebase/init"


ReactDOM.render(
  
          <BrowserRouter>
            <DataProvider>
              <App />
            </DataProvider>
          </BrowserRouter>
  ,
  document.getElementById("root")
);
