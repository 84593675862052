import React, { Fragment, ReactNode, useEffect,  } from "react";
import Login from "../../pages/registration/Login";
import {  fAuth } from "../../firebase/init";
import { LoadingView } from "../Layouts/loading";
import { useAuthState } from 'react-firebase-hooks/auth';
// component props interface
interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const [user, loading, error] = useAuthState(fAuth,);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  },  [error]);


  if (loading) {
    return <Fragment> <LoadingView /> </Fragment>;
  }

  if (!user) {
    return <Login />;
  }
  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
