import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Box, IconButton, styled, Tab, useTheme } from "@mui/material";
import { FC, Fragment, SyntheticEvent, useEffect, useRef, useState } from "react";
import PopoverLayout from "./PopoverLayout";
import { Paragraph, H6, Tiny } from "../../Typography";
import { Archive, NotificationAddOutlined } from "@mui/icons-material";
import { useGlobalState } from "../../../data-context";


const StyledTab = styled(Tab)(() => ({
  width: "50%",
  marginLeft: 0,
  marginRight: 0,
}));

const NotificationsPopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [badgeVal, setBadgeVal] = useState(0);
  const [archives, setArchives] = useState([]); 
  const {state, setState} = useGlobalState();
 
  useEffect(() => {
    if (state?.notifications && state.notifications.length > 0) {
      setBadgeVal(state.notifications.length);
    }
  }, [state]);

  const handleArchieve = (id: string) => {
    const archieveItem = state?.notifications.find((item) => item.id === id);
    if (archieveItem) {
      setArchives(prevArchives => [...prevArchives, archieveItem]);
      setState(prevState => ({
        ...prevState,
        notifications: prevState.notifications.filter((item) => item.id !== id)
      }));
    }
  }


  const handleTabChange = (_: SyntheticEvent, value: string) => {
    setTabValue(value);
  };

  return (
    <Fragment>
      <IconButton ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge color="error" badgeContent={badgeVal}>
          <NotificationAddOutlined fontSize="small" sx={{ color: "text.disabled" }} />
        </Badge>
      </IconButton>

      <PopoverLayout
        title="Notifications"
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
      >
        <TabContext value={tabValue}>
          <TabList onChange={handleTabChange}>
            <StyledTab value="1" label={`Notifications (${state?.notifications?.length || 0 })`} />
            <StyledTab value="2" label="Archived" />
          </TabList>

          <TabPanel value="1">
            {state?.notifications?.length === 0 ? (
              <Paragraph fontWeight="500" textAlign="center" p={2}>
                There are no new notifications
              </Paragraph>
            ) : <>
                {state?.notifications?.slice().reverse().map((msg) => (
                  <ListItem archieve={handleArchieve} msg={msg} key={msg.id} />
                ))
              }
              </>
            }
          </TabPanel><TabPanel value="2">
            {archives.length === 0 ? (
              <Paragraph fontWeight="500" textAlign="center" p={2}>
                There are no archives
              </Paragraph>
            ) : <>
                {archives.map((msg) => (
                  <ListItem isArchievedView archieve={handleArchieve} msg={msg} key={msg.id} />
                ))
              }
              </>
            }
          </TabPanel>
        </TabContext>
      </PopoverLayout>
    </Fragment>
  );
};

// ListItem component props
type ListItemProps = {
  archieve: (id) => void;
  isArchievedView?: boolean;
  msg: {
    id: string;
    type: string;
    image: string;
    name: string;
    message: string;
  };
};
function ListItem({archieve,isArchievedView,  msg }: ListItemProps) {
  const theme = useTheme();
  const colorbg =
    theme.palette.mode === "light" ? "secondary.light" : "divider";

  return (
    <Box
      p={2}
      alignItems="center"
      sx={{
        borderBottom: 1,
        cursor: "pointer",
        borderColor: "divider",
        borderRadius: "16px",
        backgroundColor: msg.type === "new" ? colorbg : "transparent",
        "&:hover": { backgroundColor: colorbg },
      }}
    >
      <Box alignItems="center">
        <Box
          sx={{
            width: 8,
            height: 8,
            marginRight: 2,
            borderRadius: "50%",
            backgroundColor:
              msg.type === "new" ? "primary.main" : "text.disabled",
          }}
        />
      </Box>
      <Box ml={2}>
        <H6>{msg.name}</H6>
        <Tiny display="block" fontWeight={500} color="text.disabled">
          {msg.message}
        </Tiny>
      </Box>
      {isArchievedView ? <> </> : <IconButton onClick={() => archieve(msg.id)} size="small"> <Archive /> </IconButton>}
    </Box>
  );
}

export default NotificationsPopover;
