import { AccessTimeOutlined, Dashboard, DiscountOutlined, FileDownloadOutlined, FolderSharedOutlined, ForumOutlined, PeopleAltOutlined, Person, PersonOutline, RequestQuoteOutlined, SchoolOutlined, SettingsApplicationsOutlined, } from "@mui/icons-material";

export const index = [
 
  
  {
    title: "Rota",
    Icon: AccessTimeOutlined,
    path: "/",
  },
  {
    title: "Dashboard",
    Icon: ForumOutlined,
    path: "/feed",
  },
  {
    title: "Documents",
    Icon: FolderSharedOutlined,
    path: "/docs",
  },
  {
    title: "Discount",
    Icon: DiscountOutlined,
    path: "/discounts",
  },
  {
    title: "Settings",  
    Icon: PersonOutline,
    path: "/settings",
  },
];


export const MobileIndex = [

  
  {
    title: "Dashboard",
    Icon: ForumOutlined,
    path: "/feed",
  },
  {
    title: "Documents",
    Icon: FolderSharedOutlined,
    path: "/docs",
  },
  {
    title: "Rota",
    Icon: AccessTimeOutlined,
    path: "/",
  },
  {
    title: "Discount",
    Icon: DiscountOutlined,
    path: "/discounts",
  },
  {
    title: "Settings",  
    Icon: PersonOutline,
    path: "/settings",
  },
];
